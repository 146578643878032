import React from 'react';

const SocialLinks = () => {
    return (
        <div className='social-links'>
            <div className="TextAlignCenter" style={{ flexFlow: 'row wrap', alignItems: 'center' }}>
                <a href="https://open.spotify.com/artist/444KERNPfWuFIVw7TxEev6?si=abVnf3xoSj6N0XlLa3d6kA" aria-label>
                    <title />
                    <svg width={200} height={200} viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" style={{ fill: 'rgb(187, 1, 18)', width: '24px', height: '24px', padding: '4px' }}>
                        <path d="M149.167 90.8334C122.5 75.0001 77.9167 73.3334 52.5 81.2501C48.3333 82.5001 44.1667 80.0001 42.9167 76.2501C41.6667 72.0834 44.1667 67.9168 47.9167 66.6668C77.5 57.9168 126.25 59.5834 157.083 77.9168C160.833 80.0001 162.083 85.0001 160 88.7501C157.917 91.6667 152.917 92.9167 149.167 90.8334ZM148.333 114.167C146.25 117.083 142.5 118.333 139.583 116.25C117.083 102.5 82.9167 98.3334 56.6667 106.667C53.3333 107.5 49.5833 105.833 48.75 102.5C47.9167 99.1668 49.5833 95.4168 52.9167 94.5834C83.3333 85.4168 120.833 90.0001 146.667 105.833C149.167 107.083 150.417 111.25 148.333 114.167ZM138.333 137.083C136.667 139.583 133.75 140.417 131.25 138.75C111.667 126.667 87.0833 124.167 57.9167 130.833C55 131.667 52.5 129.583 51.6667 127.083C50.8333 124.167 52.9167 121.667 55.4167 120.833C87.0833 113.75 114.583 116.667 136.25 130C139.167 131.25 139.583 134.583 138.333 137.083ZM100 16.6667C89.0565 16.6667 78.2202 18.8222 68.1097 23.0101C57.9992 27.198 48.8126 33.3363 41.0744 41.0745C25.4464 56.7026 16.6667 77.8987 16.6667 100C16.6667 122.101 25.4464 143.298 41.0744 158.926C48.8126 166.664 57.9992 172.802 68.1097 176.99C78.2202 181.178 89.0565 183.333 100 183.333C122.101 183.333 143.298 174.554 158.926 158.926C174.554 143.298 183.333 122.101 183.333 100C183.333 89.0566 181.178 78.2203 176.99 68.1098C172.802 57.9993 166.664 48.8127 158.926 41.0745C151.187 33.3363 142.001 27.198 131.89 23.0101C121.78 18.8222 110.943 16.6667 100 16.6667Z" />
                    </svg>
                </a>
                <a href="https://music.apple.com/au/artist/breakfast-road/1447318383" aria-label>
                    <title />
                    <svg width={200} height={200} viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" style={{ fill: 'rgb(187, 1, 18)', width: '24px', height: '24px', padding: '4px' }}>
                        <path d="M154.886 6.27409C153.574 6.27409 144.259 8.10402 67.8875 22.3525C67.2906 22.3525 64.435 23.0957 62.8662 24.4017C61.3269 25.6343 60.5069 27.2796 60.2044 29.2166C58.93 34.2865 62.9906 125.687 59.2119 129.972C57.5619 131.799 54.6535 132.326 52.4068 132.738C38.9709 135.085 30.0259 136.314 23.6616 142.353C12.6933 153.014 18.3764 171.616 34.5525 174.639C40.6149 175.754 52.0004 174.24 58.2912 170.769C63.8306 167.93 68.0306 162.825 69.5994 156.038C70.9475 150.22 70.5344 154.242 70.5344 66.7188C70.5344 62.4263 71.74 61.3066 75.5556 60.4849C75.5556 60.4849 142.002 48.1087 145.074 47.5878C149.376 46.8395 151.432 47.9987 151.432 52.1735C151.432 111.508 152.187 110.348 149.792 113.033C148.142 114.86 146.184 115.374 143.937 115.785C130.494 118.133 122.376 118.545 116.012 124.584C107.961 132.412 108.069 144.828 117.019 152.326C123.686 157.389 131.169 158.636 140.341 156.955C150.786 155.011 158.577 149.263 160.971 139.043C162.054 134.384 161.706 141.279 161.662 12.8373C161.662 8.50846 159.187 5.97329 154.886 6.27409Z" />
                    </svg>
                </a>
                <a href="https://soundcloud.com/breakfastroad" aria-label="soundcloud.com/">
                    <title>soundcloud.com/</title>
                    <svg width={200} height={200} viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" style={{ fill: 'rgb(187, 1, 18)', width: '24px', height: '24px', padding: '4px' }}>
                        <path d="M96.3333 73.9164V141.666H169.333C184.75 140.583 191.667 131.083 191.667 119.416C191.667 107.083 182.333 97.1664 169.833 97.1664C166.667 97.1664 164 97.8331 161.25 98.9998C159.25 79.4998 142.667 64.2498 122.25 64.2498C112.5 64.2498 103.25 67.9164 96.3333 73.9164ZM89 82.4164C86.5 80.9164 83.8333 79.7498 80.9167 79.1664V141.666H92.5V77.8331C91.25 79.1664 90.0833 80.8331 89 82.4164ZM69.4167 77.9164V141.666H77.0833V78.1664C75.5 77.9164 73.9167 77.8331 72.25 77.8331C71.25 77.8331 70.3333 77.8331 69.4167 77.9164ZM54.1667 83.3331V141.666H61.75V79.4998C59 80.4164 56.4167 81.7498 54.1667 83.3331ZM40.25 104.166C39.75 104.166 39.25 103.666 38.6667 103.416V141.666H46.3333V90.4998C43.25 94.4998 41.1667 99.2498 40.25 104.166ZM23.25 101.833V140.916C25 141.416 27 141.666 29.1667 141.666H31V101.166C30.3333 101.083 29.6667 101 29.1667 101C27 101 25 101.333 23.25 101.833ZM8.33334 121.333C8.33334 127.583 11.1667 133.083 15.5833 136.833V105.916C11.1667 109.583 8.33334 115.166 8.33334 121.333Z" />
                    </svg>
                </a>
                <a href="https://www.youtube.com/channel/UCk0JHaE0s0Avx4nJasi_QYw" aria-label="youtube.com/">
                    <title>youtube.com/</title>
                    <svg width={200} height={200} viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" style={{ fill: 'rgb(187, 1, 18)', width: '24px', height: '24px', padding: '4px' }}>
                        <path d="M83.3333 125L126.583 100L83.3333 75.0001V125ZM179.667 59.7501C180.75 63.6667 181.5 68.9167 182 75.5834C182.583 82.2501 182.833 88.0001 182.833 93.0001L183.333 100C183.333 118.25 182 131.667 179.667 140.25C177.583 147.75 172.75 152.583 165.25 154.667C161.333 155.75 154.167 156.5 143.167 157C132.333 157.583 122.417 157.833 113.25 157.833L100 158.333C65.0833 158.333 43.3333 157 34.75 154.667C27.25 152.583 22.4166 147.75 20.3333 140.25C19.25 136.333 18.5 131.083 18 124.417C17.4166 117.75 17.1666 112 17.1666 107L16.6666 100C16.6666 81.7501 18 68.3334 20.3333 59.7501C22.4166 52.2501 27.25 47.4167 34.75 45.3334C38.6666 44.2501 45.8333 43.5001 56.8333 43.0001C67.6666 42.4167 77.5833 42.1667 86.75 42.1667L100 41.6667C134.917 41.6667 156.667 43.0001 165.25 45.3334C172.75 47.4167 177.583 52.2501 179.667 59.7501Z" />
                    </svg>
                </a>
                <a href="https://instagram.com/breakfastroad" aria-label="instagram.com/">
                    <title>instagram.com/</title>
                    <svg width={200} height={200} viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" style={{ fill: 'rgb(187, 1, 18)', width: '24px', height: '24px', padding: '4px' }}>
                        <path d="M65 16.6667H135C161.667 16.6667 183.333 38.3334 183.333 65.0001V135C183.333 147.819 178.241 160.113 169.177 169.177C160.113 178.241 147.819 183.333 135 183.333H65C38.3333 183.333 16.6667 161.667 16.6667 135V65.0001C16.6667 52.1813 21.7589 39.8875 30.8232 30.8233C39.8874 21.759 52.1812 16.6667 65 16.6667ZM63.3333 33.3334C55.3768 33.3334 47.7462 36.4941 42.1201 42.1202C36.494 47.7463 33.3333 55.3769 33.3333 63.3334V136.667C33.3333 153.25 46.75 166.667 63.3333 166.667H136.667C144.623 166.667 152.254 163.506 157.88 157.88C163.506 152.254 166.667 144.623 166.667 136.667V63.3334C166.667 46.7501 153.25 33.3334 136.667 33.3334H63.3333ZM143.75 45.8334C146.513 45.8334 149.162 46.9309 151.116 48.8844C153.069 50.8379 154.167 53.4874 154.167 56.2501C154.167 59.0128 153.069 61.6623 151.116 63.6158C149.162 65.5693 146.513 66.6668 143.75 66.6668C140.987 66.6668 138.338 65.5693 136.384 63.6158C134.431 61.6623 133.333 59.0128 133.333 56.2501C133.333 53.4874 134.431 50.8379 136.384 48.8844C138.338 46.9309 140.987 45.8334 143.75 45.8334ZM100 58.3334C111.051 58.3334 121.649 62.7233 129.463 70.5373C137.277 78.3513 141.667 88.9494 141.667 100C141.667 111.051 137.277 121.649 129.463 129.463C121.649 137.277 111.051 141.667 100 141.667C88.9493 141.667 78.3512 137.277 70.5372 129.463C62.7232 121.649 58.3333 111.051 58.3333 100C58.3333 88.9494 62.7232 78.3513 70.5372 70.5373C78.3512 62.7233 88.9493 58.3334 100 58.3334ZM100 75.0001C93.3696 75.0001 87.0107 77.634 82.3223 82.3224C77.6339 87.0108 75 93.3697 75 100C75 106.631 77.6339 112.989 82.3223 117.678C87.0107 122.366 93.3696 125 100 125C106.63 125 112.989 122.366 117.678 117.678C122.366 112.989 125 106.631 125 100C125 93.3697 122.366 87.0108 117.678 82.3224C112.989 77.634 106.63 75.0001 100 75.0001Z" />
                    </svg>
                </a>
                <a href="https://twitter.com/breakfastroad" aria-label="twitter.com/">
                    <title>twitter.com/</title>
                    <svg width={200} height={200} viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" style={{ fill: 'rgb(187, 1, 18)', width: '24px', height: '24px', padding: '4px' }}>
                        <path d="M187.167 49.9999C180.75 52.9166 173.833 54.8333 166.667 55.7499C174 51.3333 179.667 44.3333 182.333 35.9166C175.417 40.0833 167.75 42.9999 159.667 44.6666C153.083 37.4999 143.833 33.3333 133.333 33.3333C113.75 33.3333 97.75 49.3333 97.75 69.0833C97.75 71.9166 98.0833 74.6666 98.6667 77.2499C69 75.7499 42.5833 61.4999 25 39.9166C21.9167 45.1666 20.1667 51.3333 20.1667 57.8333C20.1667 70.2499 26.4167 81.2499 36.0833 87.4999C30.1667 87.4999 24.6667 85.8333 19.8333 83.3333C19.8333 83.3333 19.8333 83.3332 19.8333 83.5832C19.8333 100.917 32.1667 115.417 48.5 118.667C45.5 119.5 42.3333 119.917 39.0833 119.917C36.8333 119.917 34.5833 119.667 32.4167 119.25C36.9167 133.333 50 143.833 65.75 144.083C53.5833 153.75 38.1667 159.417 21.3333 159.417C18.5 159.417 15.6667 159.25 12.8333 158.917C28.6667 169.083 47.5 175 67.6667 175C133.333 175 169.417 120.5 169.417 73.2499C169.417 71.6666 169.417 70.1666 169.333 68.5832C176.333 63.5832 182.333 57.2499 187.167 49.9999Z" />
                    </svg>
                </a>
                <a href="https://tiktok.com/@breakfastroad" aria-label="tiktok.com/@">
                    <title>tiktok.com/@</title>
                    <svg width={200} height={200} viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" style={{ fill: 'rgb(187, 1, 18)', width: '24px', height: '24px', padding: '4px' }}>
                        <path d="M107.867 13.4255V131.484C107.867 144.526 97.2917 155.092 84.2583 155.092C71.2167 155.092 60.65 144.517 60.65 131.484C60.65 118.442 71.225 107.876 84.2583 107.876V76.3922C53.8333 76.3922 29.1667 101.059 29.1667 131.484C29.1667 161.909 53.8333 186.576 84.2583 186.576C114.683 186.576 139.35 161.909 139.35 131.484V76.3922L141.008 77.2255C150.267 81.8589 160.475 84.2672 170.825 84.2672V52.7755L169.883 52.5422C151.933 48.0589 139.342 31.9255 139.342 13.4255H107.867Z" />
                    </svg>
                </a>
            </div>
        </div>
    );
};

export default SocialLinks;