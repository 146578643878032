import React from 'react';
import '../styles/components/SimpleForm.css';

export const SimpleForm = ({ status, message, onSubmitted }) => {
    let input;
    const submit = () =>
        input &&
        input.value.indexOf("@") > -1 &&
        onSubmitted({
            EMAIL: input.value
        });

    return (
        <div className='form-container'>
            <input
                ref={node => (input = node)}
                type="email"
                placeholder="email"
            />
            <button onClick={submit}>confirm</button>
            {status === "sending" && <h3>sending...</h3>}
            {status === "error" && (
                <h3
                    style={{ color: "red" }}>invalid</h3>
            )}
            {status === "success" && (
                <h3
                    style={{ color: "black" }}>talk soon!</h3>
            )}
        </div>
    );
};

export default SimpleForm;