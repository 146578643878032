import React from 'react';
import '../styles/components/Video.css';

const Video = ({ videoSrcURL, videoTitle }) => {
    return (
        <div className='video'>
            <iframe
                src={videoSrcURL}
                title={videoTitle}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                className='video-iframe'
                allowFullScreen
            />
        </div>
    );
};

export default Video;