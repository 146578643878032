import * as React from "react"
import BlockHighlight from "../components/BlockHighlight"
import BlockLink from "../components/BlockLink"
import '../styles/pages/index.css'
import { graphql } from "gatsby"
import Video from "../components/Video"
import { Helmet } from "react-helmet"
import MailchimpSubscribe from "react-mailchimp-subscribe";
import SimpleForm from "../components/SimpleForm"
import SocialLinks from "../components/SocialLinks"
import logo from "../images/logo.png"

const IndexPage = ({ data }) => {
  return (
    <main className="main">
      <Helmet>
        <title>Breakfast Road</title>
        <meta name="description" content="West Sydney's one and only boyband." />
        <meta property="og:title" content="Breakfast Road" />
        <meta property="og:type" content="music.musician" />
        <meta property="og:url" content="https://breakfastroad.com/" />
        <meta property="og:image" content="https://breakfastroad.com/band.jpg" />
      </Helmet>
      <title>Breakfast Road</title>
      <div className="header">
        <img className="header-logo" src={logo} />
      </div>
      <div className="content">
        <h2 className="header-subtitle">what's new?</h2>
        {data.allContentfulBlockLink.nodes.map(node => (
          <BlockLink title={node.title} thumbnail={node.thumbnail.gatsbyImageData} caption={node.caption} link={node.link} />
        ))}
        <SocialLinks />
      </div>
      <div className='content'>
        <h2 className="header-subtitle">tap in</h2>
        <MailchimpSubscribe
          url="https://breakfastroad.us14.list-manage.com/subscribe/post?u=53be52ac3677ac917fc9471c2&amp;id=3562148c47"
          render={({ subscribe, status, message }) => (
            <SimpleForm
              status={status}
              message={message}
              onSubmitted={formData => subscribe(formData)}
            />
          )}
        />
      </div>
      <div className="content">
        <h2 className="header-subtitle">watch</h2>
        {data.allContentfulFeatureVideo.nodes.map(node => (
          <Video videoSrcURL={node.embedUrl} videoTitle={node.title} />
        ))}
      </div>
      <div className="content">
        <h2 className="header-subtitle">play</h2>
        {data.allContentfulGame.nodes.map(node => (
          <BlockLink title={node.title} thumbnail={node.thumbnail.gatsbyImageData} caption={node.caption} link={node.link} />
        ))}
      </div>
    </main>
  )
}

export default IndexPage

export const query = graphql`
query MyQuery {
  allContentfulBlockLink(sort: {order: DESC, fields: updatedAt}) {
    nodes {
      title
      link
      caption
      thumbnail {
        gatsbyImageData(width: 100)
      }
    }
  }
  allContentfulFeatureVideo {
    nodes {
      title
      embedUrl
    }
  }
  allContentfulGame {
    nodes {
      title
      link
      caption
      thumbnail {
        gatsbyImageData(width: 100)
      }
    }
  }
}
`