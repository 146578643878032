import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import '../styles/components/BlockLink.css';
import { navigate } from 'gatsby';

const BlockLink = ({ title, caption, link, thumbnail }) => {
    return (
        <div className='block-link' onClick={() => navigate(link)}>
            <GatsbyImage image={thumbnail} className='link-image' />
            <div className='link-copy'>
                <h3>{title}</h3>
                <p>{caption}</p>
            </div>
        </div>
    );
};

export default BlockLink;